<template>
  <div
    v-if="!isFetchingCmsSite && cmsSite && cmsSite.isDevelopment"
    class="mb-2"
  >
    <b-row>
      <b-col class="text-right">
        <b-button
          variant="info"
          @click="$bvModal.show('cms-generate-demo')"
        >
          Get demo URL
        </b-button>
      </b-col>
    </b-row>
    <b-modal
      id="cms-generate-demo"
      title="Give me your frontend URL"
      no-close-on-backdrop
      @ok="onOk"
    >
      <validation-observer ref="cmsFormRef">
        <b-form @submit.prevent="onSubmit">
          <validation-provider
            v-slot="{ errors }"
            name="site_url"
            rules="required"
          >
            <b-form-group label="Site url">
              <b-form-input
                id="site_url"
                v-model="createDemoLink.url"
                placeholder="https://some-awesome.com"
                :state="errors[0] ? false : null"
              />
            </b-form-group>
          </validation-provider>
        </b-form>
      </validation-observer>
      <a
        v-if="isGenerated"
        :href="finalLink"
        target="_blank"
      >{{
        finalLink
      }}</a>
    </b-modal>
  </div>
</template>

<script>
import { required } from '@validations'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      required,
      createDemoLink: {
        url: '',
      },
      isGenerated: false,
      finalLink: '',
    }
  },
  computed: {
    ...mapState({
      cmsSite: (state) => state.cms.cmsSite,
      isFetchingCmsSite: (state) => state.cms.isFetchingCmsSite,
      userInfo: (state) => state.user.userInfo,
    }),
  },
  mounted() {
    this.createDemoLink.url = this.userInfo.registrationUrl
  },
  methods: {
    async onOk(e) {
      e.preventDefault()
      const valid = await this.$refs.cmsForm.validate()
      if (valid) {
        this.onSubmit(() => this.$bvModal.hide('cms-generate-demo'))
      }
    },
    onSubmit() {
      this.$refs.cmsFormRef.validate().then( async (success) => {
        if (success) {
         if (this.createDemoLink.url.length > 0) {
          this.finalLink = `${this.createDemoLink.url}/demo?siteId=${this.cmsSite.id}`
          this.isGenerated = true
        }
        }
      })
    },
  },
}
</script>
